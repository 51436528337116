<style>
.table.dataTable.no-footer th:before {
  color: transparent;
  opacity: 1;
  height: 6px;
  width: 6px;
  background: transparent;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(-45deg);
}

.table.dataTable.no-footer th:after {
  color: transparent;
  opacity: 1;
  height: 6px;
  width: 6px;
  background: transparent;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(135deg);
}
</style>
<template>
  <div class="profile-data-wrapper test" v-show="!loading">
    <template
      v-if="
        userPermissions &&
        userPermissions.profile_verification &&
        userPermissions.profile_verification.length > 0 &&
        userPermissions.profile_verification.includes('View')
      "
    >
      <!--div class="profile-options">
        <div class="row align-items-center justify-content-between">
          <div class="col">
            <router-link class="btn primary-btn" :to="{ name: 'ProfileRoster' }"
              ><font-awesome-icon :icon="['fas', 'arrow-left']"
            /></router-link>
          </div-->
          <!-- End div center   -->
        <!--/div>
      </div-->

      <div class="custom-table no-arrows">
        <table class="table" id="recent-verification-history">
          <thead>
            <tr>
              <th>Date</th>
              <th class="no-sort">Profile</th>
              <th class="no-sort">Uploaded Files</th>
              <th class="no-sort">Person Responsible</th>
              <th class="no-sort">Upwork Comments</th>
              <th class="no-sort">Closing Comments</th>
              <th class="no-sort">Failure Reason</th>
              <th class="no-sort">Verification Request Date</th>
              <th class="no-sort">Verification Closing Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="data in recentVerifications" :key="data.Id">
              <td>{{ $filters.dateFormat(data.created_at, "DD/MM/YYYY") }}</td>
              <td>{{ data.uproster_profile.ProfileName }}</td>
              <td>
              <template v-if="data.documents.length > 0">
                <ul class="verification-list">
                  <li v-for="document in data.documents" :key="document.id">
                    <a
                      :href="`${uploadPath + document.Documents}`"
                      class="underline-link"
                      target="_blank"
                      > <font-awesome-icon :icon="['fas',getFileTypeIcons(document.DocumentName.split('.').pop().toLowerCase())]" /> {{ document.DocumentName }}</a
                    >
                  </li>
                </ul>
              </template>
              <template v-else>
                N/A
              </template>

              </td>
              <td>{{ data.b_d_team_profile.Name }}</td>
              <td>{{ (data.UpworksComment && data.UpworksComment.length > 95 ) ? data.UpworksComment.substring(0,95)+"..." : (data.UpworksComment) ? data.UpworksComment : 'N/A' }}</td>
              <td>{{ (data.ClosingComment && data.ClosingComment.length > 95 ) ? data.ClosingComment.substring(0,95)+"..." : (data.ClosingComment) ?  data.ClosingComment : 'N/A'}}</td>
              <td>{{ (data.FailureComment && data.FailureComment.length > 95 ) ? data.FailureComment.substring(0,95)+"..." : (data.FailureComment) ?  data.FailureComment : 'N/A' }}</td>
              <td>{{ (data.requestDate ) ? $filters.dateFormat(data.requestDate, "DD/MM/YYYY") : 'N/A'}}</td>
              <td>{{ (data.updated_at && data.Status !== 'inProgress') ? $filters.dateFormat(data.updated_at, "DD/MM/YYYY") : 'N/A'}}</td>
              <td>
                <template v-if="data.Status === 'failure'">Failure</template>
                <template v-if="data.Status === 'verified'">Verified</template>
                <template v-if="data.Status === 'inProgress'">In-Progress</template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template
      v-if="
        userPermissions &&
        userPermissions.profile_verification &&
        (userPermissions.profile_verification.length === 0 ||
          (userPermissions.profile_verification.length > 0 &&
            !userPermissions.profile_verification.includes('View')))
      "
      ><AccessDenied/></template
    >
  </div>
</template>
<script>
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
//import { library } from "@fortawesome/fontawesome-svg-core";
//import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

//library.add(faArrowLeft);

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";
import AccessDenied from "../components/AccessDenied.vue";
import verificationDashboardService from "../services/verificationDashboard.service";

import common from "../mixins/common";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";

import {fas} from "@fortawesome/free-solid-svg-icons";

library.add(fas)

export default {
  data() {
    return {
      loading: true,
      uploadPath: process.env.VUE_APP_UPLOAD_BASE_PATH,
      sideModals: {
        addEdit: { show: false },
        newRecord: { show: false },
        viewStats: { show: false },
        import: { show: false },
        view: { show: false },
      },
      viewStats: {
        status: false,
        BDTeamProfileName: "",
        data: {},
      },
      verification: {},
      recentVerifications: [],
      verificationStatus: [],
    };
  },
  mixins: [common],
  components: {
    AccessDenied,
    FontAwesomeIcon
  },
  beforeCreate(){
    this.$store.commit("loginLoadingStatus", true);
  },
  async mounted() {
    await this.getData();
    this.loading = false;
  },
  methods: {
    async getData() {
      let vm = this;
      //await vm.$store.dispatch("getUserDetails");
      const userPermissions = await vm.userPermissions;
      if (
        userPermissions &&
        userPermissions.profile_verification &&
        userPermissions.profile_verification.length > 0
      ) {
        try {
          const profileId = vm.$route.params.ID;
         this.$store.commit("loginLoadingStatus", false);
          vm.$store.commit("loadingStatus", true);
          const response = await verificationDashboardService.getHistory(profileId);
          vm.recentVerifications = response.verifiedProfileData;

          var dT = $("#recent-verification-history").DataTable();
          dT.destroy();

          var dTT = $("#verification-status").DataTable();
          dTT.destroy();

          vm.$nextTick(function () {
            var d = new Date(),
              month = "" + (d.getMonth() + 1),
              day = "" + d.getDate(),
              year = d.getFullYear();

            if (month.length < 2) month = "0" + month;
            if (day.length < 2) day = "0" + day;

            let tableOptions = {
              dom: "Bfrtip",
              buttons: [
                {
                  extend: "csv",
                  filename: "Recent Verifications " + [year, month, day].join("-"),
                  extension: ".csv",
                  exportOptions: {
                    columns: "th:not(:last-child)",
                  },
                },
              ],
              iDisplayLength: 10,
              bLengthChange: false,
              ordering: true,
              columnDefs: [{
                orderable: false,
                targets: "no-sort"
              }],
              info: false,
              /* Disable initial sort */
              aaSorting: [],
              fnDrawCallback: function (oSettings) {
                if (oSettings._iDisplayLength >= oSettings.fnRecordsDisplay()) {
                  $(oSettings.nTableWrapper).find(".dataTables_paginate").hide();
                } else {
                  $(oSettings.nTableWrapper).find(".dataTables_paginate").show();
                }
              },
            };

            $("#recent-verification-history").DataTable(tableOptions);
            tableOptions.iDisplayLength = 50;
            $("#verification-status").DataTable(tableOptions);

            $(".dt-buttons, .dataTables_filter").hide();
          });
          vm.$store.commit("loadingStatus", false);
          vm.$store.commit("loginLoadingStatus", false);
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      }
    },
    getFileTypeIcons(fileType){
      var output = '';  
      if(fileType == 'png' || fileType == 'jpg' || fileType == 'jpeg'){
           output = 'file-image';
        }else if(fileType == 'pdf'){
          output = 'file-pdf';
        }else if(fileType == 'xls' || fileType == 'xlsx'){
          output = 'file-excel';
        }else if(fileType == 'docx'){
          output = 'file-word';
        }else if(fileType == 'csv'){
          output = 'file-csv';
        }
        else if(fileType == 'txt'){
          output = 'file';
        }
     return output;   
    }
  },
  watch: {
    userPermissions: {
      handler() {
        this.getData();
      },
    },
  },
};
</script>
